import React, {Component} from 'react';
import LeftMenu from "./LeftMenu";
import axios from "axios";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";
import {BubbleLoader} from 'react-css-loaders';
import avatarMan from "./../images/businessman.png"
import avatarWoman from "./../images/businesswoman.png"
import Search from "./Search";
import Footer from "../Footer";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import TopBasket from "./TopBasket";
import basket from "./../images/basket.gif";
import {ST} from "../global/SystemText";
import * as moment from "moment";
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/hu';
import 'moment/locale/sk';
import 'moment/locale/en-gb';
import Language from "./Language";


export default class B2B extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            loaded2: false,
            data: {},
            showMenu: false,
            showModal: false,
            showAmountAlert: false,
            openUser: false,
            modalElement: ""
        }
    }

    componentWillUnmount() {
        window.removeEventListener('addToBasket', function () {
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        ST.clean();
        await ST.init();
        window.addEventListener('addToBasket', function (e) {
            this.setState({
                showModal: true,
                modalElement: e.detail.label,
                showAmountAlert: e.detail.amountAlert
            });
        }.bind(this));

        window.addEventListener('handleMobileLeftMenu', function (e) {
            this.setState({
                showMenu: !this.state.showMenu
            });
        }.bind(this));

        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                action: "GET_PORTAL_SETTINGS"
            }
        }).then((response) => {
            window.portalSettings = response.data;
            document.title = response.data.portal_name;
            document.documentElement.lang = "";
            //PT.resetBasket();
            PT.initBasket().then(() => {
                setTimeout(() => {
                    this.setLang();
                    this.setState({
                        loaded: true
                    });
                }, 1000)
            });


        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });

    }

    setLang() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                action: "GET_PORTAL_LANG_ISO"
            }
        }).then((response) => {
            moment.locale(response.data.lang);
            document.documentElement.lang = response.data.lang;
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    getAfterDueInvoices() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                idCurrency: Session.getItem("idCurrency"),
                action: "GET_PORTAL_AFTERDUE_INVOICES"
            }
        }).then((response) => {
            this.setState({
                data: response.data,
                loaded2: true
            });

        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    toggleUserOpen() {
        this.setState({openUser: !this.state.openUser})
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    toggleMenuOpen() {
        this.setState({showMenu: !this.state.showMenu})
        document.addEventListener('mousedown', this.handleClickOutside);
    }


    handleClickOutside = () => {
        setTimeout(() => {
            this.setState({
                openUser: false,
                showMenu: false
            }, () => {
                document.removeEventListener('mousedown', this.handleClickOutside)
            })
        }, 200);
    }

    render() {

        let avatar = avatarMan;
        if (Session.getItem("contactGender") === "2" || Session.getItem("contactGender") === "3") {
            avatar = avatarWoman;
        }
        let toLogin = !(("true" === Session.getItem("logged")) && Number(Session.getItem("logoutTimer")) >= Date.now());
        if (toLogin) {
            return (<Redirect from={Session.getItem("basename") + this.props.location.pathname}
                              to={Session.getItem("basename") + "/login?logout=true"}/>);
        } else {
            if (window.cordova) {
                //2 tyzdne
                Session.setItem("logoutTimer", Date.now() + 1000 * 3600 * 24 * 14);
            } else {
                Session.setItem("logoutTimer", Date.now() + 30 * 60000);
            }
            if (!this.state.loaded2) {
                this.getAfterDueInvoices();
            }
            if (this.state.loaded === true) {
                return (
                    <div>
                        <style>
                            {window.portalSettings.portal_customCSS}
                        </style>
                        <div className={"navbar-top " + (this.state.showMenu ? "sidebar-mobile-main" : "")}>
                            <div
                                className={"navbar navbar-fixed-top " + (PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "navbar-inverse" : "")}
                                style={{background: window.portalSettings.portal_pcolor}}>
                                <div className="navbar-header">
                                    <ul className="nav navbar-nav pull-left visible-sm-block visible-xs-block">
                                        <li>
                                            <a onClick={() => this.toggleMenuOpen()}>
                                                <i className="icon-paragraph-justify3"/>
                                            </a>
                                        </li>

                                    </ul>
                                    <ul className="nav navbar-nav pull-left">
                                        {PT.canShowToDealer() ? (
                                            <li className={"dropdown dropdown-user " + (this.state.openUser ? "open" : "")}>
                                                <a className="dropdown-toggle" onClick={() => this.toggleUserOpen()}>
                                                <span>
                                                <div className="contact-name">
                                                    <b>{Session.getItem("contactName")}</b>
                                                </div>
                                                <div className="company-name">{Session.getItem("companyName")}</div>
                                                </span>
                                                    <i className="caret"/>
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-left">
                                                    <li>
                                                        <Link to={Session.getItem("basename") + "/"}>
                                                            <i className="icon-home"/> {ST.get("home", "global")}
                                                        </Link>
                                                    </li>
                                                    <li className="divider"></li>
                                                    <li>
                                                        <Link to={Session.getItem("basename") + "/login?logout=true"}>
                                                            <i className="icon-switch2"/> {ST.get("logout", "global")}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>) : null}
                                    </ul>

                                </div>

                                <div className="navbar-collapse secondary-menu ">
                                    <ul className="nav navbar-nav pull-right">

                                        {PT.canShowToDealer() ? (
                                            <li className="dropdown visible-xs-block mobile-icon">
                                                <Language/>
                                            </li>
                                        ) : null}
                                        {PT.canShowToDealer() ? (
                                            <li className="">
                                                <Search {...this.props}/>
                                            </li>
                                        ) : null}
                                        {PT.canShowToDealer() ? (
                                            <li className="">
                                                <TopBasket/>
                                            </li>
                                        ) : null}


                                    </ul>
                                </div>

                            </div>
                            <div
                                className={"topbar navbar navbar-fixed-top " + (PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "navbar-inverse" : "")}
                                style={{background: window.portalSettings.portal_pcolor, display: "none"}}>
                                <div className="navbar-collapse collapse">
                                    <ul className="nav navbar-nav ">
                                        <li className="dropdown dropdown-user p-10">
                                            <Link className="dropdown-toggle no-padding"
                                                  to={Session.getItem("basename")}>
                                                <div style={{float: "left"}}>
                                                    <div className="contact-name">
                                                        <b>{Session.getItem("contactName")}</b></div>
                                                    <div className="company-name">{Session.getItem("companyName")}</div>
                                                </div>
                                            </Link>
                                        </li>

                                        <li className="dropdown visible-xs-block mobile-icon"
                                            style={{float: "right", marginRight: "15px"}}>
                                            <div onClick={() => this.setState({showMenu: !this.state.showMenu})}
                                                 className="sidebar-mobile-main-toggle"><i
                                                className="icon-paragraph-justify3"/></div>
                                        </li>

                                        {PT.canShowToDealer() ? (
                                            <li className="dropdown visible-xs-block mobile-icon"
                                                style={{float: "right", marginRight: "20px"}}>
                                                <TopBasket/>
                                            </li>
                                        ) : null}

                                        {PT.canShowToDealer() ? (
                                            <li className="dropdown visible-xs-block mobile-icon"
                                                style={{float: "right", marginRight: window.cordova ? "10px" : "20px"}}>
                                                <Search {...this.props}/>
                                            </li>
                                        ) : null}
                                        {PT.canShowToDealer() ? (
                                            <li className="dropdown visible-xs-block mobile-icon"
                                                style={{float: "right", marginRight: window.cordova ? "10px" : "20px"}}>
                                                <Language/>
                                            </li>
                                        ) : null}
                                    </ul>
                                    {PT.canShowToDealer() ? (
                                        <ul className="nav navbar-nav navbar-right hidden-xs">
                                            <li className="p-10">
                                                <Search {...this.props}/>
                                            </li>
                                            <li style={{padding: "10px 10px 0 10px"}}>
                                                <TopBasket/>
                                            </li>
                                            <li style={{padding: "12px 10px 0 10px"}}>
                                                <Language/>
                                            </li>
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                            <div className="page-container no-padding ">
                                <div className="page-content">
                                    <LeftMenu {...this.props}/>
                                    <div className="content-wrapper pt-20 pl-10 pr-10">
                                        {this.state.loaded2 && this.state.data.count > 0 ?
                                            <Row>
                                                <Col md={12}>
                                                    <Link to={Session.getItem("basename") + "/documents/invoice"}>
                                                        <div className="alert alert-info bg-danger alert-styled-right">
                                                        <span
                                                            className="text-semibold">{ST.get("unpaid_invoices_overdue_due", "global")} </span> {this.state.data.amount} {this.state.data.currencyCode}
                                                        </div>
                                                    </Link>
                                                </Col>
                                            </Row>
                                            : null}
                                        {this.props.children}
                                        <Footer settings={window.portalSettings}/>
                                        <Button onClick={()=>this.setState({showModal: true})}>OPEN</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal size="md"
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}>
                            <Modal.Body className="p-15">
                                <Row>
                                    <Col md={3}>
                                        ff
                                        <img src={basket} className="img-responsive" alt="basket"/>
                                    </Col>
                                    <Col md={9}>
                                        <p className="text-size-large" style={{fontSize: "18px"}}><b
                                            style={{color: window.portalSettings.portal_pcolor}}>{this.state.modalElement}</b> {ST.get("added_to_basket", "global")}.
                                        </p>
                                        {Boolean(window.portalSettings.show_prices) ? (
                                            <p className="text-size-large"
                                               style={{fontSize: "14px"}}>{ST.get("items_in_basket", "global")}: <b
                                                style={{color: window.portalSettings.portal_pcolor}}>{(window.basket.totalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</b>
                                            </p>
                                        ) : null}
                                        {this.state.showAmountAlert &&
                                            <p className="text-size-large"
                                               style={{fontSize: "16px"}}><b
                                                style={{color: window.portalSettings.portal_pcolor}}>{ST.get("aler", "portal")} </b> {ST.get("added_amount_changed", "portal")}
                                            </p>}

                                        <div className="btn-group pull-right">
                                            <Button onClick={() => this.setState({showModal: false})}
                                                    variant={"light"}>{ST.get("continue_shopping", "global")}</Button>
                                            <Link onClick={() => this.setState({showModal: false})}
                                                  to={Session.getItem("basename") + "/basket"}
                                                  className="btn btn-primary "
                                                  style={{background: window.portalSettings.portal_pcolor}}>{ST.get("go_to_basket", "global")}</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>

                        </Modal>
                    </div>

                );
            } else {
                return (<BubbleLoader/>);
            }

        }
    }
}

