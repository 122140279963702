import React, {Component} from 'react';
import Breadcrumbs from "./Breadcrumbs";
import {Link} from "react-router-dom";
import {Button, Col, Modal, Row} from "react-bootstrap";
import * as PT from "../global/PortalTools";
import {resetBasket, Session} from "../global/PortalTools";
import axios from "axios";
import {ST} from "../global/SystemText";
import empty_cart from "../images/empty_cart.png";
import basket from "../images/basket.gif";
import {BubbleLoader} from "react-css-loaders";
import QuickOrder from "./QuickOrder";
import WarningDialog from "../components/WarningDialog";


export default class Basket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            update: false,
            loaded: false,
            loadedBasket: false,
            loadedAddresses: false,
            loadedPayments: false,
            selectedTransport: 0,
            selectedAddress: -2,
            acceptCond: false,
            showModal: false,
            showModalQuotation: false,
            showModalReset: false,
            showProductEanWarning: false,
            showQo: false,
            order: {},
            quotation: {},
            customerNumber:"",
            note:"",
            minPrice:0,
            deliveryError:false,
            confirmed:false,
            selectedPayment:0
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loaded) {
            this.getTransports();
        }
    }

    componentDidMount() {
        window.addEventListener('updateBasket', function (e) {
            this.setState({
                loaded: false
            });
        }.bind(this));

        this.checkBasket();
        this.setState({
            loadedBasket: true,
        });
        this.getTransports();
        if(window.portalSettings.portal_orderin_use_payment) {
            this.getPayments();
        }
        this.getAddresses();
        this.getMinPrice();
    }

    componentWillUnmount() {
        window.removeEventListener('updateBasket', function () {
        });
    }

    changeInput = async (index, event) => {
        await PT.changeAmountInBasket(index, event.target.value);
    };

    async checkBasket() {
        //return
        //console.log(window.basket);
        let contactId= Session.getItem("idContact")
        if(contactId && window.portalSettings.persist_cart){
            let response = await axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                params: {
                    idMandant: Session.idMandant,
                    idContact: contactId,
                    idLanguage: Session.getItem("idLanguage"),
                    action: "GET_PORTAL_CART"
                }
            })
            if(response.data && response.data!==""){
                let storedBasket=response.data.replaceAll("&quot;","\"")
                if (storedBasket === null || storedBasket === undefined) {
                    window.basket = {
                        items: [],
                        itemsCount: 0,
                        totalWeight: 0,
                        totalPrice: 0,
                        totalPriceVat: 0,
                        vatKoef: 0,
                        codeCurrency: Session.getItem("codeCurrency")
                    }
                } else {
                    try{
                        window.basket = JSON.parse(storedBasket);
                    }catch (e){
                        window.basket = {
                            items: [],
                            itemsCount: 0,
                            totalWeight: 0,
                            totalPrice: 0,
                            totalPriceVat: 0,
                            vatKoef: 0,
                            codeCurrency: Session.getItem("codeCurrency")
                        }
                    }
                }
            }
        }
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: Session.getItem("idContact"),
            idCompany: Session.getItem("idCompany"),
            idCurrency: Session.getItem("idCurrency"),
            idLanguage: Session.getItem("idLanguage"),
            priceLevel: Session.getItem("priceLevel"),
            basket: window.basket,
            action: "CHECK_BASKET"
        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }}).then(async (response) => {
            this.setBasket(response.data.basket);
            //console.log(response.data.basket);
            await PT.updateBasket();
            this.setState({
                loadedBasket: true,
            });

        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    setBasket(basket) {
        window.basket = basket;
    }

    getTransports() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                idCurrency: Session.getItem("idCurrency"),
                idLanguage: Session.getItem("idLanguage"),
                weight: window.basket.totalWeight,
                price: window.basket.totalPrice,
                action: "GET_PORTAL_TRANSPORTS",

            }
        }).then((response) => {

            let defaultTransport=0;
            response.data.map((element, index) => {
                if(element.id===Number(window.portalSettings.portal_orderin_default_transport)){
                    defaultTransport=index;
                }
            });


            this.setState({
                loaded: true,
                transports: response.data,
                selectedTransport:defaultTransport

            });

        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }
    getPayments() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                idCurrency: Session.getItem("idCurrency"),
                idLanguage: Session.getItem("idLanguage"),
                action: "GET_PORTAL_PAYMENTS",

            }
        }).then((response) => {
                this.setState({
                    loadedPayments: true,
                    selectedPayment:response.data[0]?response.data[0].id:0,
                    payments: response.data,

            });

        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    getAddresses() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                action: "GET_ADDRESSES_FOR_PORTAL"
            }
        }).then((response) => {
            let newState = {
                loadedAddresses: true,
                addresses: response.data

            };
            if (response.data.limited === 'true') {
                //newState['selectedAddress'] = 0;
            }
            this.setState(newState);
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    getMinPrice() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                idCurrency: Session.getItem("idCurrency"),
                action: "GET_MIN_PRICE_FOR_PORTAL"
            }
        }).then((response) => {
           this.setState({
               minPrice:response.data.minPrice
           })
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    confirmOrder() {
        if(this.state.selectedAddress === -2){
            this.setState({deliveryError:true})
            return;
        }
        this.setState({
            deliveryError:false,
            confirmed:true
        });
        let delivery = {};
        if (this.state.selectedAddress < 0) {
            delivery.deliveryName = this.state.addresses.invoice.name;
            delivery.deliveryStreet = this.state.addresses.invoice.street;
            delivery.deliveryCity = this.state.addresses.invoice.city;
            delivery.deliveryZip = this.state.addresses.invoice.zip;
            delivery.deliveryIdCountry = this.state.addresses.invoice.idCountry;
        } else {
            delivery.deliveryName = this.state.addresses.delivery[this.state.selectedAddress].name;
            delivery.deliveryStreet = this.state.addresses.delivery[this.state.selectedAddress].street;
            delivery.deliveryCity = this.state.addresses.delivery[this.state.selectedAddress].city;
            delivery.deliveryZip = this.state.addresses.delivery[this.state.selectedAddress].zip;
            delivery.deliveryIdCountry = this.state.addresses.delivery[this.state.selectedAddress].idCountry;
        }
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: Session.getItem("idContact"),
            idCompany: Session.getItem("idCompany"),
            idCurrency: Session.getItem("idCurrency"),
            idLanguage: Session.getItem("idLanguage"),
            priceLevel: Session.getItem("priceLevel"),
            note: this.state.note,
            customerNumber: this.state.customerNumber,
            ...window.basket,
            ...delivery,
            transport: this.state.transports[this.state.selectedTransport].id,
            payment: this.state.selectedPayment,
            isDealer:Session.getItem("dealerLogin") === "true",

            action: "SEND_ORDER"

        },{headers: {'Content-type': 'application/json; charset=utf-8',
        }}).then((response) => {
            PT.resetBasket();
            //this.props.history.replace(Session.getItem("basename")+"/quotation/"+response.data);
            this.setState({
                order: response.data.order,
                showModal: true,
                deliveryError:false,
                confirmed:false
            });
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    confirmQuotation() {
        if(this.state.selectedAddress === -2){
            this.setState({deliveryError:true})
            return;
        }
        this.setState({
            deliveryError:false,
            confirmed:true
        });
        let delivery = {};
        if (this.state.selectedAddress < 0) {
            delivery.deliveryName = this.state.addresses.invoice.name;
            delivery.deliveryStreet = this.state.addresses.invoice.street;
            delivery.deliveryCity = this.state.addresses.invoice.city;
            delivery.deliveryZip = this.state.addresses.invoice.zip;
            delivery.deliveryIdCountry = this.state.addresses.invoice.idCountry;
        } else {
            delivery.deliveryName = this.state.addresses.delivery[this.state.selectedAddress].name;
            delivery.deliveryStreet = this.state.addresses.delivery[this.state.selectedAddress].street;
            delivery.deliveryCity = this.state.addresses.delivery[this.state.selectedAddress].city;
            delivery.deliveryZip = this.state.addresses.delivery[this.state.selectedAddress].zip;
            delivery.deliveryIdCountry = this.state.addresses.delivery[this.state.selectedAddress].idCountry;
        }
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: Session.getItem("idContact"),
            idCompany: Session.getItem("idCompany"),
            idCurrency: Session.getItem("idCurrency"),
            idLanguage: Session.getItem("idLanguage"),
            priceLevel: Session.getItem("priceLevel"),
            note: this.state.note,
            customerNumber: this.state.customerNumber,
            ...window.basket,
            ...delivery,
            transport: this.state.transports[this.state.selectedTransport].id,
            payment: this.state.selectedPayment,
            isDealer:Session.getItem("dealerLogin") === "true",

            action: "SEND_QUOTATION"

        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }}).then((response) => {
            PT.resetBasket();
            //this.props.history.replace(Session.getItem("basename")+"/quotation/"+response.data);
            this.setState({
                quotation: response.data.quotation,
                showModalQuotation: true,
                deliveryError:false,
                confirmed:false
            });
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    changeAddress(event) {
        this.setState({selectedAddress: Number(event.target.value)})
    }
    changePayment(event) {
        this.setState({selectedPayment: Number(event.target.value)})
    }

    async hideQo() {
        this.setState({showQo: false});
        await this.checkBasket()
    }

    changeCustomerNumber(event){
        this.setState({customerNumber: event.target.value})
    }
    changeNote(event){
        this.setState({note: event.target.value})
    }

    _getProductByEan = (ean) => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    idCatalogue: "0",
                    idCurrency: Session.getItem("idCurrency"),
                    idLanguage: Session.getItem("idLanguage"),
                    priceLevel: Session.getItem("priceLevel"),
                    ean: ean,
                    action: "GET_PRODUCT_BY_EAN"
                }
            })
            .then(async (response) => {
                let product = response.data;
                if (product.id > 0) {
                    await PT.addToBasket(1, product.idParent, 0, product, null, false)
                } else {
                    this.setState({
                        showProductEanWarning: true
                    })
                }
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
                this.setState({
                    showProductEanWarning: true
                })
            })
    }

    _scanBarCode = () => {
        window.cordova.plugins.barcodeScanner.scan(
            (result) => {
                let ean = result.text;
                this._getProductByEan(ean);
            },
            (error) => {
                navigator.notification.alert(
                    "Scanning failed: " + error,  // message
                    () => {
                    },         // callback
                    'Error',            // title
                    'Done'                  // buttonName
                );
            },
            {
                preferFrontCamera : false, // iOS and Android
                showFlipCameraButton : true, // iOS and Android
                showTorchButton : true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                saveHistory: false, // Android, save scan history (default false)
                resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                disableAnimations : true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        );
    }

    render() {
        try {
            if (this.state.loadedBasket) {
                let result = null;
                let pivFontSize="20px";
                let pevFontSize="17px";
                if (Boolean(window.portalSettings.main_price_excl_vat)){
                    pivFontSize="17px";
                    pevFontSize="20px";
                }
                if (window.basket.items.length > 0) {
                    result = (
                        <div>
                            <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                                <Breadcrumbs active={ST.get("basket", "global")}/>
                                <div className="panel-heading">
                                    <h1 className="panel-title">
                                        {ST.get("basket", "global")}
                                    </h1>
                                    <div className="heading-elements">
                                        <Button className={"btn-primary"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={() => this.setState({showQo: true})}>{ST.get("quick_order", "portal")}</Button>
                                        {window.cordova ? <Button className={"btn-primary ml-10"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={this._scanBarCode}>{ST.get("scan_ean", "portal")}</Button>
                                            : null}
                                    </div>
                                    <div className="heading-elements-icons">
                                        <i className="icon icon-wallet" onClick={() => this.setState({showQo: true})} style={{fontSize: "22px"}}></i>
                                        {window.cordova ? <i className="icon icon-barcode2 ml-15" onClick={this._scanBarCode} style={{fontSize: "22px"}}></i> : null}
                                    </div>
                                </div>
                                <div className="panel-body">

                                    <div className="table-responsive">
                                        <table className="table table-lg">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>{ST.get("title", "global")}</th>
                                                <th>{ST.get("product_order_our", "product")}</th>
                                                <th style={{width: "120px"}}>{ST.get("amount", "global")}</th>
                                                {Boolean(window.portalSettings.show_prices) && window.basket.showDiscountColumns ? <th>{ST.get("price_before_discount", "global")}</th> : null}
                                                {Boolean(window.portalSettings.show_prices) && window.basket.showDiscountColumns ? <th>{ST.get("discount", "global")}</th> : null}
                                                {Boolean(window.portalSettings.show_prices) ? (<th>{ST.get("total_pc_excl_vat", "global")}</th>) : null }
                                                {Boolean(window.portalSettings.show_prices) ? (<th>{ST.get("total_pc_vat", "global")}</th>) : null }
                                                {Boolean(window.portalSettings.show_prices) ? (<th>{ST.get("exclvattotal", "global")}</th>) : null }
                                                {Boolean(window.portalSettings.show_prices) ? (<th>{ST.get("vattotal", "global")}</th>) : null }
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {window.basket.items.map((element, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index+1}.</td>
                                                        <td><Link
                                                            to={Session.getItem("basename") + "/product/" + element.id}><b>{element.name}</b></Link>
                                                        </td>
                                                        <td><Link
                                                            to={Session.getItem("basename") + "/product/" + element.id}>{element.orderOur}</Link>
                                                        </td>
                                                        <td className="pr-20"><input min={1} className="form-control form-control-bordered" disabled={element.lockAmount}
                                                                                     type={"number"}
                                                                                     onChange={this.changeInput.bind(this, index)}
                                                                                     value={element.amount}/></td>
                                                        {Boolean(window.portalSettings.show_prices) && window.basket.showDiscountColumns ? <td>{element.basePriceFormated}</td> : null}
                                                        {Boolean(window.portalSettings.show_prices) && window.basket.showDiscountColumns ? <td>{element.discount}%</td> : null}
                                                        {Boolean(window.portalSettings.show_prices) ? (<td>{element.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</td>) : null }
                                                        {Boolean(window.portalSettings.show_prices) ? (<td>{element.priceVat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</td>) : null }
                                                        {Boolean(window.portalSettings.show_prices) ?
                                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                <td><b
                                                                    style={{color: window.portalSettings.portal_pcolor}}>{(element.price * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</b>
                                                                </td>
                                                                :
                                                                <td>{(element.price * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</td>
                                                         : null }
                                                        {Boolean(window.portalSettings.show_prices) ?
                                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                <td>{(element.priceVat * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</td>
                                                                :
                                                                <td><b
                                                                    style={{color: window.portalSettings.portal_pcolor}}>{(element.priceVat * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</b>
                                                                </td>
                                                            : null }
                                                        <td><Button onClick={async () => await PT.removeFromBasket(index)}
                                                                    variant={"light"} size={"sm"}><i
                                                            className="icon icon-bin2"/></Button></td>
                                                    </tr>
                                                )
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <hr/>

                                    <Row>
                                        <Col md={8}>
                                            {window.portalSettings.portal_orderin_change_transport===undefined || window.portalSettings.portal_orderin_change_transport?
                                                <div className={"transports"}>
                                                    <h5>{ST.get("transport_type", "global")}</h5>
                                                    <Row>
                                                        {this.state.loaded ?
                                                            this.state.transports.map((element, index) => {
                                                                return (
                                                                    <Col key={index} md={12} className="transport-entry text-center type-tile">
                                                                        <Row
                                                                            onClick={() => this.setState({selectedTransport: index})}
                                                                            className={index === this.state.selectedTransport ? "active" : ""}
                                                                            style={index === this.state.selectedTransport ? {borderColor: window.portalSettings.portal_pcolor} : {}}>
                                                                            <Col className={"transport-name"} md={6}>
                                                                                {element.name}
                                                                            </Col>
                                                                            {Boolean(window.portalSettings.show_prices) ? (
                                                                                <Col md={6} className="transport-price text-bold text-size-large">
                                                                                    +{element.formatedPrice.replace(/eur/ig, "€")}
                                                                                </Col>
                                                                            ) : null }
                                                                        </Row>
                                                                    </Col>
                                                                )
                                                            })
                                                            : null}
                                                    </Row>
                                                </div>
                                                : <div>&nbsp;</div>}
                                        </Col>
                                        <Col md={4}>
                                            <div className="table-responsive">
                                                <table className="table table-sm summary-finalPrices"
                                                       style={{background: window.portalSettings.portal_pcolor + "2e"}}>
                                                    <tbody>
                                                    {window.basket.totalWeight > 0 ?
                                                        <tr>
                                                            <td>{ST.get("product_weight", "product")}</td>
                                                            <td className="text-right text-bold">{window.basket.totalWeight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} kg</td>
                                                        </tr> : null}
                                                    {Boolean(window.portalSettings.show_prices) && this.state.loaded && this.state.transports.length > 0 ?
                                                        <tr className={"summary-transport-row"}>
                                                            <td>{window.portalSettings.portal_orderin_change_transport===undefined || window.portalSettings.portal_orderin_change_transport?this.state.transports[this.state.selectedTransport].name:ST.get("transport","global")}</td>
                                                            <td className="text-right text-bold">{this.state.transports[this.state.selectedTransport].formatedPrice.replace(/eur/ig, "€")}</td>
                                                        </tr> : null}
                                                    {Boolean(window.portalSettings.show_prices) ? (
                                                        <tr>
                                                            <td style={{borderTop: "none", fontSize: pevFontSize}}>{ST.get("exclvattotal", "global")} </td>
                                                            {this.state.loaded && this.state.transports.length > 0 ?
                                                                <td className="text-right text-bold" style={{borderTop: "none", fontSize: pevFontSize}}>
                                                                    {(window.basket.totalPrice + this.state.transports[this.state.selectedTransport].price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</td>
                                                                :
                                                                <td className="text-right text-bold" style={{borderTop: "none", fontSize: pevFontSize}}>
                                                                    {(window.basket.totalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}</td>
                                                            }
                                                        </tr>
                                                    ) : null }
                                                    {Boolean(window.portalSettings.show_prices) ? (
                                                        <tr>
                                                            <td style={{fontSize: pivFontSize}}>{ST.get("vattotal", "global")}</td>
                                                            {this.state.loaded && this.state.transports.length > 0 ?
                                                                <td className="text-right text-bold" style={{fontSize: pivFontSize}}>
                                                                    {(window.basket.totalPriceVat + this.state.transports[this.state.selectedTransport].priceVat).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}
                                                                </td>
                                                                :
                                                                <td className="text-right text-bold" style={{fontSize: pivFontSize}}>
                                                                    {window.basket.totalPriceVat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}
                                                                </td>}
                                                        </tr>
                                                    ) : null }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-20"}>
                                        <Col md={12}>
                                            <h3 className="panel-title">
                                                {ST.get("global_do_order_confirm", "global")}
                                            </h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={7}>
                                            {this.state.loadedAddresses ?
                                                <Row>
                                                    <Col md={5}>
                                                        <h5>{ST.get("billing_address", "person")}</h5>
                                                        <div>
                                                            <p><b>{this.state.addresses.invoice.name}</b></p>
                                                            <p>{this.state.addresses.invoice.street}, {this.state.addresses.invoice.zip}</p>
                                                            <p>{this.state.addresses.invoice.city}, {this.state.addresses.invoice.nameCountry}</p>
                                                        </div>

                                                    </Col>
                                                    <Col md={7}>
                                                        {window.portalSettings.portal_orderin_use_payment && this.state.loadedPayments && this.state.payments.length>0?
                                                            <React.Fragment>
                                                                <h5>{ST.get("payment_type", "person")}</h5>
                                                                <select className={"form-control form-control-bordered "}
                                                                        value={this.state.selectedPayment}
                                                                        onChange={this.changePayment.bind(this)}>
                                                                    {this.state.payments.map((element, index) => {
                                                                        return (<option key={element.id}
                                                                                        value={element.id}>{element.name}</option>);
                                                                    })}
                                                                </select>
                                                            </React.Fragment>
                                                            :null}
                                                        <h5>{ST.get("delivery_address", "person")}</h5>
                                                            <select className={"form-control form-control-bordered "+(this.state.deliveryError?"has-error":"")}
                                                                    value={this.state.selectedAddress}
                                                                    onChange={this.changeAddress.bind(this)}>
                                                                <option value={-2}>{ST.get("please_select","global")}</option>
                                                                {this.state.addresses.limited === "true" ? null : (<option value={-1}>{ST.get("same_as_billing_adress", "global")}</option>)}
                                                                {this.state.addresses.delivery.map((element, index) => {
                                                                    return (<option key={index}
                                                                                    value={Number(index)}>{element.name}, {element.street}, {element.zip} {element.city}, {element.nameCountry}</option>);
                                                                })}
                                                            </select>
                                                        {this.state.deliveryError?<span className="has-error help-block">{ST.get("please_choose_delivery_address","global")}</span>:null}
                                                        <h5>{ST.get("orderin_customer_number", "document")}</h5>
                                                        <input className="form-control form-control-bordered"
                                                               type={"text"}
                                                               onChange={this.changeCustomerNumber.bind(this)}
                                                               value={this.state.customerNumber}/>
                                                    </Col>
                                                </Row>
                                                : null}
                                        </Col>
                                        <Col md={5} >
                                            <h5>{ST.get("note", "global")}</h5>
                                            <textarea rows={6} className="form-control form-control-bordered"
                                                      onChange={this.changeNote.bind(this)}
                                                      value={this.state.note}/>
                                        </Col>

                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col md={6} >

                                        </Col>

                                        {window.basket.totalPrice < this.state.minPrice ?
                                            <Col md={6} >
                                                <b>
                                                    {ST.get("min_order_price","portal")}: {this.state.minPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')} {window.basket.codeCurrency}
                                                </b>
                                            </Col>
                                            :
                                            <Col md={6} >
                                                <div style={{float:"right"}}>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" checked={this.state.acceptCond}
                                                               onChange={() => this.setState({acceptCond: !this.state.acceptCond})}/>
                                                        {ST.get("confirm_order_warning", "portal")}
                                                    </label>
                                                </div>
                                                <div className="btn-group text-right">
                                                    <Button variant={"light"}
                                                            onClick={() => this.setState({showModalReset:true})}>{ST.get("reset_basket", "global")}</Button>
                                                    {window.portalSettings.portal_quotation_request && <Button disabled={this.state.confirmed} variant={"light"} style={{
                                                        background: window.portalSettings.portal_scolor,
                                                        color: PT.getColorBrightness(window.portalSettings.portal_scolor) < 135 ? "white" : "#333"
                                                    }}
                                                            onClick={this.confirmQuotation.bind(this)}>{this.state.confirmed?<i
                                                        className="icon-spinner spinner position-left"/>:null}{ST.get("global_do_quotation_confirm", "portal")}</Button>}
                                                    <Button disabled={!this.state.acceptCond || this.state.confirmed} variant={"light"} style={{
                                                        background: window.portalSettings.portal_pcolor,
                                                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                                                    }}
                                                            onClick={this.confirmOrder.bind(this)}>{this.state.confirmed?<i
                                                        className="icon-spinner spinner position-left"/>:null}{ST.get("global_do_order_confirm", "global")}</Button>
                                                </div>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </div>
                            <Modal size="md"
                                   aria-labelledby="contained-modal-title-vcenter"
                                   centered show={this.state.showModalReset}
                                   onHide={() => this.setState({showModalReset: false})}>
                                <Modal.Body className="p-15">
                                    <Row>
                                        <Col md={12}>
                                            <h4 >{ST.get("are_you_sure", "portal")}</h4>
                                            <div className="pull-right">
                                                <Button onClick={() => this.setState({showModalReset: false})} className="btn btn-secondary ">{ST.get("close", "global")}</Button>
                                                <Button onClick={async () => {await resetBasket(); this.setState({showModalReset: false});}} className="btn btn-primary "
                                                      style={{background: window.portalSettings.portal_pcolor}}>{ST.get("reset_basket", "global")}</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>
                            <WarningDialog
                                show={this.state.showProductEanWarning}
                                onHide={() => this.setState({showProductEanWarning: false})}
                                warningText={ST.get("product_not_found", "product")}
                            />
                        </div>

                    );
                } else {
                    result = (
                        <div>
                            <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                                <Breadcrumbs active={ST.get("basket", "global")}/>
                                <div className="panel-heading">
                                    <h1 className="panel-title">
                                        {ST.get("basket", "global")}
                                    </h1>
                                    <div className="heading-elements">
                                        <Button className={"btn-primary"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={() => this.setState({showQo: true})}>{ST.get("quick_order", "portal")}</Button>
                                        {window.cordova ? <Button className={"btn-primary ml-10"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={this._scanBarCode}>{ST.get("scan_ean", "portal")}</Button>
                                            : null}
                                    </div>
                                    <div className="heading-elements-icons">
                                        <i className="icon icon-wallet" onClick={() => this.setState({showQo: true})} style={{fontSize: "22px"}}></i>
                                        {window.cordova ? <i className="icon icon-barcode2 ml-15" onClick={this._scanBarCode} style={{fontSize: "22px"}}></i> : null}
                                    </div>
                                </div>
                                <div className="panel-body" style={{background: window.portalSettings.portal_pcolor + "4e", fontSize: "17px", fontWeight: "500"}}>
                                    <Row>
                                        <Col md={1}>
                                            <img src={empty_cart} className="img-responsive" style={{width: "80px"}} alt={ST.get("basket_is_empty", "global")}/>
                                        </Col>
                                        <Col md={11} style={{padding: "25px"}}>
                                            {ST.get("basket_is_empty", "global")}
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                            <Modal size="md"
                                   aria-labelledby="contained-modal-title-vcenter"
                                   centered show={this.state.showModal}
                                   onHide={() => this.setState({showModal: false})}>
                                <Modal.Body className="p-15">
                                    <Row>
                                        <Col md={3}>
                                            <img src={basket} className="img-responsive" alt="basket"/>
                                        </Col>
                                        <Col md={9}>
                                            <p className="text-size-large center" style={{fontSize: "30px"}}><b
                                                style={{color: window.portalSettings.portal_pcolor}}>{ST.get("thank_for_purchase", "global")}</b>
                                            </p>
                                            {Number(window.portalSettings.orderin_access) > 0 ?
                                                <p className="text-size-large center" style={{fontSize: "14px"}}>
                                                    {ST.get("link_to_order", "global")}:
                                                    <b><Link style={{color: window.portalSettings.portal_pcolor}}
                                                             to={Session.getItem("basename") + "/orderin/" + this.state.order.id}>&nbsp;{this.state.order.number}</Link></b>


                                                </p>
                                                : null
                                            }
                                            <div className="pull-right">
                                                <Link onClick={() => this.setState({showModal: false})} to={Session.getItem("basename")} className="btn btn-primary "
                                                      style={{background: window.portalSettings.portal_pcolor}}>{ST.get("close", "global")}</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>
                            <Modal size="md"
                                   aria-labelledby="contained-modal-title-vcenter"
                                   centered show={this.state.showModalQuotation}
                                   onHide={() => this.setState({showModalQuotation: false})}>
                                <Modal.Body className="p-15">
                                    <Row>
                                        <Col md={3}>
                                            <img src={basket} className="img-responsive" alt="basket"/>
                                        </Col>
                                        <Col md={9}>
                                            <p className="text-size-large center" style={{fontSize: "30px"}}><b
                                                style={{color: window.portalSettings.portal_pcolor}}>{ST.get("thank_for_interest", "portal")}</b>
                                            </p>
                                            <div className="pull-right">
                                                <Link onClick={() => this.setState({showModalQuotation: false})} to={Session.getItem("basename")} className="btn btn-primary "
                                                      style={{background: window.portalSettings.portal_pcolor}}>{ST.get("close", "global")}</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>
                            <WarningDialog
                                show={this.state.showProductEanWarning}
                                onHide={() => this.setState({showProductEanWarning: false})}
                                warningText={ST.get("product_not_found", "product")}
                            />
                        </div>
                    );

                }
                return (
                    <span>
                    {result}
                        <QuickOrder show={this.state.showQo} hide={() => this.hideQo()}/>

                </span>);
            } else {
                return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
            }
        } catch (e) {
            PT.resetBasket();
            return (<div/>)
        }
    }

}
